import { IStrength } from '@obeta/models/lib/models/Strengths/Strengths'
import { contentRoutes } from '@obeta/utils/lib/variables'

export const strengths: IStrength[] = [
  {
    icon: 'addShoppingCartIcon',
    name: 'Mehrere Einkaufs\u00ADwagen',
    description: '',
    contentLink: contentRoutes.ourStrengthInfoPage,
    //description: 'Lorem ipsum dolor sit amet, consectetur, do eiusmod tempor incididunt ut.',
  },
  {
    icon: 'mobileFriendly',
    name: 'Mobile App',
    contentLink: contentRoutes.ourStrengthInfoPage,
    //description: 'Lorem ipsum dolor sit amet, consectetur, do eiusmod tempor incididunt ut.',
    description: '',
  },
  {
    icon: 'integrationInstructions',
    name: 'API-\u00ADIntegration',
    contentLink: contentRoutes.ourStrengthInfoPage,
    //description: 'Lorem ipsum dolor sit amet, consectetur, do eiusmod tempor incididunt ut.',
    description: '',
  },
  {
    icon: 'assignment',
    name: 'Projekt\u00ADmanage\u00ADment',
    contentLink: contentRoutes.ourStrengthInfoPage,
    //description: 'Lorem ipsum dolor sit amet, consectetur, do eiusmod tempor incididunt ut.',
    description: '',
  },
  {
    icon: 'currency',
    name: 'Finanz\u00ADmanage\u00ADment',
    contentLink: contentRoutes.ourStrengthInfoPage,
    //description: 'Lorem ipsum dolor sit amet, consectetur, do eiusmod tempor incididunt ut.',
    description: '',
  },
  {
    icon: 'supportAgent',
    name: 'Kompetente Fach\u00ADberatung',
    contentLink: contentRoutes.ourStrengthInfoPage,
    //description: 'Lorem ipsum dolor sit amet, consectetur, do eiusmod tempor incididunt ut.',
    description: '',
  },
]
